<template>
  <div>
    <CDropdown
      :togglerText="$t('role.orders.rounds.orders.details.modifyBtn')"
      color="primary"
      :disabled="!canView(PERMS.ORDERS_EDIT)"
    >
      <CDropdownItem @click="setDelivered()">{{
        $t("role.orders.rounds.orders.actionLabels.markAsDelivered")
      }}</CDropdownItem>
      <CDropdownItem @click="setNotDelivered()">{{
        $t("role.orders.rounds.orders.actionLabels.markAsNotDelivered")
      }}</CDropdownItem>
      <CDropdownItem @click="createIncident(INCIDENT_TYPE.INCIDENT)">{{
        $t("role.orders.rounds.orders.actionLabels.openIncident")
      }}</CDropdownItem>

      <CDropdownItem @click="createIncident(INCIDENT_TYPE.CANCELLATION)">{{
        $t("role.orders.rounds.orders.actionLabels.cancel")
      }}</CDropdownItem>
    </CDropdown>

    <div class="d-flex justify-content-center m-3" v-if="loading">
      <CSpinner color="info" />
    </div>

    <ejs-grid
      ref="grid"
      v-show="!loading"
      :dataSource="rows"
      :allowSelection="true"
      :selectionSettings="selectionSettings"
      :detailTemplate="detailTemplate"
      :dataBound="onDataBound"
      @rowSelected="rowSelectedHandler($event)"
      @rowDeselected="rowDeselectedHandler($event)"
      @detailDataBound="onDetailDataBound"
    >
      <e-columns>
        <e-column type="checkbox" width="40" textAlign="left"></e-column>
        <e-column
          field="id"
          headerText=""
          isPrimaryKey="{true}"
          width="40"
          :template="countTemplate"
        ></e-column>
        <e-column
          field="ppm"
          :headerText="$t('role.orders.rounds.orders.details.table.name')"
          :template="nameTemplate"
        ></e-column>
        <e-column
          field="ppm"
          :headerText="$t('role.orders.rounds.orders.details.table.producer')"
          :template="producerTemplate"
          width="250"
        ></e-column>
        <e-column
          field="ppm"
          :headerText="
            $t('role.orders.rounds.orders.details.table.conservation')
          "
          width="80"
          :template="conservationTemplate"
        ></e-column>
        <e-column
          field="ppm"
          :headerText="$t('role.orders.rounds.orders.details.table.price')"
          width="100"
          :template="priceTemplate"
        ></e-column>
        <e-column
          field="ppm"
          headerText=""
          width="40"
          :template="actionsTemplate"
        ></e-column>
      </e-columns>
    </ejs-grid>
  </div>
</template>

<script>
import { DetailRow } from "@syncfusion/ej2-vue-grids";
import { Query, Predicate } from "@syncfusion/ej2-data";
import { GetDataManagerNew } from "../../../../../../ds";
import ProductsTableCount from "./ProductsTableCount";
import ProductsTableName from "./ProductsTableName";
import ProductsTableProducer from "./ProductsTableProducer";
import ProductsTableConservation from "./ProductsTableConservation";
import ProductsTablePrice from "./ProductsTablePrice";
import ProductsTableActions from "./ProductsTableActions";
import ProductsTableRowDetails from "./ProductsTableRowDetails";
import EventBus from "../../../../../../helpers/EventBus";
import { buildPredicate } from "../../../../../../helpers/common";
import orderManagementMixin from "../../../share/mixin";
import { filtersMap } from "../../filtersMap";
import { PERMS } from "../../../../../../helpers/acl";
import { INCIDENT_TYPE } from "../../../../../../config/global";

export default {
  name: "ProductsTable",

  mixins: [orderManagementMixin],

  props: {
    order: { type: Object, required: true },
  },

  provide: {
    grid: [DetailRow],
  },

  watch: {
    order() {
      this.itemSelected = Object.assign({});
      this.loadData();
    },
  },

  data: function () {
    const roleId = this.$store.state.role.id;
    const roundId = this.$store.state.rounds.round.id;
    const endpoint = filtersMap("orders.panel1.list.endpoint");
    const dm = GetDataManagerNew(endpoint, [roleId, roundId]);

    return {
      INCIDENT_TYPE,
      PERMS,
      dm: dm,
      loading: false,
      rows: [],
      selectionSettings: {
        persistSelection: false,
        enableToggle: true,
        checkboxOnly: true,
      },

      itemSelected: {},

      countTemplate: () => {
        return { template: ProductsTableCount };
      },

      nameTemplate: () => {
        return { template: ProductsTableName };
      },

      producerTemplate: () => {
        return { template: ProductsTableProducer };
      },

      conservationTemplate: () => {
        return { template: ProductsTableConservation };
      },

      priceTemplate: () => {
        return { template: ProductsTablePrice };
      },

      actionsTemplate: () => {
        return { template: ProductsTableActions };
      },

      detailTemplate: () => {
        return {
          template: {
            extends: ProductsTableRowDetails,
            propsData: {
              grid: this.$refs.grid,
            },
          },
        };
      },
    };
  },

  mounted() {
    EventBus.$on("orders:detail", this.onDetailsSelected);
    this.loadData();
  },

  beforeDestroy() {
    EventBus.$off("orders:detail", this.onDetailsSelected);
  },

  methods: {
    loadData() {
      const query = this.prepareQuery();
      this.loading = true;

      this.dm
        .executeQuery(query)
        .then((response) => {
          this.loading = false;
          this.rows = response.result;
        })
        .catch((response) => {
          this.loading = false;
          console.error(response);
        });
    },

    prepareQuery() {
      const { keyword } = this.$store.state.rounds.filters;
      let predicate = new Predicate("order.id", "equal", this.order.order.id);

      const map = filtersMap("orders.panel1.list.filters");
      predicate = buildPredicate(
        predicate,
        map,
        this.$store.state.rounds.filters
      );

      let query = new Query().where(predicate);

      const searchFields = filtersMap("orders.panel1.list.search", []);
      if (keyword && searchFields.length > 0) {
        query = query.search(keyword, searchFields);
      }

      return query;
    },

    getSelectedParentKey() {
      return "odetails";
    },

    getSelectedChildrenKey() {
      return "details";
    },

    createIncident(type) {
      const selected = this.getSelected();
      if (selected) {
        const entities = [];
        Object.keys(selected).forEach((type) => {
          entities.push({ type, items: [...selected[type]] });
        });

        this.$store.commit("incidents/createResetAllEntities");
        this.$store.commit("incidents/createAddEntities", {
          type: type,
          entities,
          show: true,
        });
      }
    },

    setDelivered() {
      const selected = this.getSelected();
      if (selected) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("orders.statusCode.next"),
            ...this.mapSelectedToIds(selected),
          })
          .then(this.onUpdateStatusSuccess);
      }
    },

    setNotDelivered() {
      const selected = this.getSelected();
      if (selected) {
        this.$store
          .dispatch("rounds/updateStatusBatch", {
            status: filtersMap("orders.statusCode.prev"),
            ...this.mapSelectedToIds(selected),
          })
          .then(this.onUpdateStatusSuccess);
      }
    },

    canView(perm) {
      return this.$store.getters["connections/havePermission"](perm);
    },
  },
};
</script>
